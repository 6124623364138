<template>
  <div style="position: relative;">
    <el-tabs
      v-model="activeName"
      @tab-click="handleClick"
      type="border-card"
      class="top_nav"
    >
      <el-tab-pane label="定期定额纳税申报表" name="1">
        <div class="right_box" v-if="!this.paramCheck.comId">
          
          <el-button size="small" @click="printingNew()" type="success">打印</el-button>
            <el-button type="primary" @click="download()" size="small" plain>导出</el-button>
        </div>
        <div class="top_title3">
          <h4>个人所得税(定期定额纳税申报表)</h4>
          <h5>小规模纳税人适用</h5>
          <div class="bottom_font clearfix">
            <h6 class="left">
              纳税人名称：<span>{{ comName }}</span>
            </h6>
            <h6 class="center"><span></span></h6>
            <!-- 税款所属期：{{durationOfTaxTime}} -->
            <h6 class="right">
              <p>单位：元</p>
              <p>
                纳税人识别号：<span>{{ taxNo }}</span>
              </p>
            </h6>
          </div>
        </div>
        <div class="big_box4">
          <!-- <div>
              <el-radio-group v-model="expStatus">
                <el-radio :label="true">使用公式</el-radio>
                <el-radio :label="false">不使用公式</el-radio>
              </el-radio-group>
            </div> -->
          <table class="content" cellspacing="0" style="width:2400px !important;overflow-x: overlay;">
            <tr class="top_bg">
              <td class="center" style="width:5%">征收项目</td>
              <td class="center" style="width:5%">征收品目</td>
              <td class="center" style="width:5%">征收子目</td>
              <td class="center" style="width:5%">应税项(2)</td>
              <td class="center" style="width:5%">计税依据(3)</td>
              <td class="center" style="width:6%">税款所属期起</td>
              <td class="center" style="width:6%">税款所属期止</td>
              <td class="center" style="width:5%">核定计税依据(4)</td>
              <td class="center" style="width:5%">税率(征收率)(5)</td>
              <td class="center" style="width:5%">本期应纳税额(6)</td>
              <td class="center" style="width:5%">核定应纳税额(7)</td>
              <td class="center" style="width:5%">减免性质</td>
              <td class="center" style="width:5%">减免税额</td>
              <td class="center" style="width:6%">个体工商户减半征收个人所得税</td>
              <td class="center" style="width:6%">小规模减征减免性质</td>
              <td class="center" style="width:6%">小规模减征比例%</td>
              <td class="center" style="width:5%">小规模减征额</td>
              <td class="center" style="width:5%">本期已缴税额</td>
              <td class="center" style="width:5%">本期应补（退）税额</td>
            </tr>
              <tr>
                <td><input type="text" v-model="list[0].zsxm"></td>
                <td><input type="text" v-model="list[0].zspm"></td>
                <td><input type="text" v-model="list[0].zszm"></td>
                <qzf-str-td :disabled="false" v-model:amount="list[0].ysx" @change="changeList()"></qzf-str-td>
                <qzf-str-td :disabled="expStatus" v-model:amount="list[0].jsyj" @change="changeList()"></qzf-str-td>
                <td >
                    <el-date-picker value-format="YYYY-MM-DD" size="small" v-model="list[0].skssqq" type="date" placeholder="选择日期时间" style="width: 100%;"></el-date-picker>
                </td>
                <td >
                    <el-date-picker value-format="YYYY-MM-DD" size="small" v-model="list[0].skssqz" type="date" placeholder="选择日期时间" style="width: 100%;"></el-date-picker>
                </td>
                <qzf-str-td :disabled="false" v-model:amount="list[0].hdjsyj" @change="changeList()"></qzf-str-td>
               <td> <input type="text" v-model="list[0].sl_zsl"></td>
                <!-- <qzf-str-td :disabled="expStatus" v-model:amount="list[0].sl_zsl" @change="changeList()"></qzf-str-td> -->
                <!-- <td>{{ list[0].sl_zsl }}</td> -->
                <qzf-str-td :disabled="false" v-model:amount="list[0].bqynse" @change="changeList()"></qzf-str-td>
                <qzf-str-td :disabled="expStatus" v-model:amount="list[0].hdynse" @change="changeList()"></qzf-str-td>
                <td> <input type="text" v-model="list[0].jmxz"></td>
                <qzf-str-td :disabled="expStatus" v-model:amount="list[0].jmse" @change="changeList()"></qzf-str-td>
                <qzf-str-td :disabled="expStatus" v-model:amount="list[0].gtjmzs" @change="changeList()"></qzf-str-td>
                <td> <input type="text" v-model="list[0].xgmjzjmxz"></td>
                <qzf-str-td :disabled="expStatus" v-model:amount="list[0].xgmjzbl" @change="changeList()"></qzf-str-td>
                <qzf-str-td :disabled="expStatus" v-model:amount="list[0].xgmjze" @change="changeList()"></qzf-str-td>
                <qzf-str-td :disabled="expStatus" v-model:amount="list[0].bqyjse" @change="changeList()"></qzf-str-td>
                <qzf-str-td :disabled="expStatus" v-model:amount="list[0].bqybtse" @change="changeList()"></qzf-str-td>
              </tr>
              <tr>
                <td class="bg_color">合计</td>
                <td>--</td>
                <td>--</td>
                <td>{{list[0].ysx}}</td>
                <td>{{list[0].jsyj}}</td>
                <td>--</td>
                <td>--</td>
                <td>--</td>
                <td>--</td>
                <td>{{list[0].bqynse}}</td>
                <td>{{list[0].hdynse}}</td>
                <td>--</td>
                <td>{{list[0].jmse}}</td>
                <td>{{list[0].gtjmzs}}</td>
                <td>--</td>
                <td>--</td>
                <td>{{list[0].xgmjze}}</td>
                <td>{{list[0].bqyjse}}</td>
                <td>{{list[0].bqybtse}}</td>
             </tr>
          </table>
        </div>
        <div class="save_box" v-if="$buttonStatus('swbb_bc')">
          <el-button type="primary" @click="saveValues()" size="small"><i class="iconfont icon-baocun"></i>保存</el-button>
        </div>
      </el-tab-pane>
      <!-- 附加税 end -->
    </el-tabs>
  </div>
</template>

<script>
import { gsInfo, gsEdit } from "@/api/taxCalculation.js";
import { report, exportReport } from "@/api/printSet";

export default {
  name: "dqde",
  data() {
    return {
      address: "",
      durationOfTaxTime: "",
      activeName: "1",
      name: "1",
      listQuery: {},
      list: [{}],
      period: "",
      comName: this.$store.getters["user/comInfo"].comName,
      taxNo: this.$store.getters["user/comInfo"].taxNo,
      expStatus: false,
      param: {
        comIds: [this.$store.getters["user/comInfo"].comId],
        period: this.$store.getters["user/comInfo"].period,
        bookNames: ["gs_dqde"],
      },
      startAccountPeriod: "",
      comId: 0,
    };
  },
  props: {
    paramCheck: {
      default: {},
      type: Object,
    },
  },
  created() {
    if (this.paramCheck.comId) {
      // //console.log("查看报表");
      this.startAccountPeriod = this.paramCheck.period;
      this.comId = this.paramCheck.comId;
    }
    this.getList();
  },
  methods: {
    // 公式
    changeList() {
      if (!this.expStatus) {
        return;
      }
    
    },
    getList() {
      if (this.startAccountPeriod) {
        let param = {
          tableName: "gs_tax_dqde",
          period: this.startAccountPeriod,
          comId: this.comId,
        };
        gsInfo(param).then((res) => {
          if (res.data.data.info) {
            this.list = res.data.data.info;
            this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
          }
        });
      } else {
        gsInfo({ tableName: "gs_tax_dqde" }).then((res) => {
          if (res.data.data.info) {
            this.list = res.data.data.info;
            this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
          }
        });
      }
    },
    saveValues() {
      if (this.startAccountPeriod) {
        let param = {
          tableName: "gs_tax_dqde",
          items: this.list,
          period: this.startAccountPeriod,
          comId: this.comId,
        };
        gsEdit(param).then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("保存成功");
            this.getList();
          }
        });
      } else {
        let param = {
          tableName: "gs_tax_dqde",
          items: this.list,
        };
        gsEdit(param).then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("保存成功");
            this.getList();
          }
        });
      }
    },
    printingNew() {
      report(this.param).then((res) => {
        if (res.data.msg == "success") {
          window.open(res.data.data.url);
        }
      });
    },
    download() {
      exportReport(this.param).then((res) => {
        if (res.data.msg == "success") {
          window.open(res.data.data.url);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 180px !important;
}
.right_box {
  width: 25%;
  text-align: right;
  margin-bottom: 15px;
  margin-right: 16px;
  position: absolute;
  top: 10px;
  right: 3px;
}
.save_box {
  margin: 0 auto;
  margin-top: 30px;
  text-align: right;
}
.center {
  text-align: center;
  color: #333 !important;
}
.weight {
  font-size: 13px !important;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}
.left_k {
  text-indent: 41px;
}
.content {
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  span {
    line-height: 26px;
    font-size: 13px;
  }
  tr {
    padding: 0;
    margin: 0;
  }
  td {
    border-right: 1px solid #c2c2c2;
    border-bottom: 1px solid #c2c2c2;
    line-height: 26px;
    padding: 0 6px;
    color: #333;
    font-size: 13px;
  }
  input {
    width: 100%;
    line-height: 26px;
    // padding: 0 6px;
    font-size: 13px;
  }
}


.big_box4 {
  width: 100%;
  // padding: 40px;
  background: #fff;
  // box-shadow: 0 2px 10px #ddd;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: auto;
}
</style>
